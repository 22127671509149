/* Documentation Modal Styles */
.doc-modal-overlay {
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .doc-modal {
    background-color: #0e0e0e;
    width: 80%;
    height: 80%;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    position: relative;
  }
  
  /* Sidebar Styling */
  .doc-sidebar {
    width: 250px;
    background: #333333;
    color: #ecf0f1;
    padding: 20px;
    overflow-y: auto;
    position: relative;
  }
  
  .doc-sidebar h2 {
    margin-top: 0;
    color: #ffa500;
  }
  
  .doc-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .doc-sidebar li {
    margin: 10px 0;
    cursor: pointer;
    padding: 5px 0;
    transition: color 0.2s;
  }
  
  .doc-sidebar li.active {
    color: #ffa500;
    font-weight: bold;
  }
  
  .doc-sidebar li:hover {
    text-decoration: underline;
  }
  
  /* Watermark in Sidebar */
  .doc-sidebar::after {
    content: "";
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    width: 200px;
    height: 100px;
    opacity: 0.2;
    pointer-events: none;
  }
  
  /* Content Area */
  .doc-content-area {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    color: #fff;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .doc-content-area section {
    margin-bottom: 40px;
  }
  
  .doc-content-area section h2 {
    font-size: 28px;
    color: #ffa500;
    border-bottom: 2px solid #555;
    padding-bottom: 5px;
    margin-top: 20px;
  }
  
  .doc-content-area section h3 {
    font-size: 22px;
    color: #ffa500;
    margin-top: 20px;
  }
  
  .doc-content-area section ul {
    margin: 10px 0 0 20px;
  }
  
  .doc-content-area section ul li {
    margin: 5px 0;
  }
  
  .doc-content-area a {
    color: #ffa500;
    text-decoration: none;
  }
  
  .doc-content-area a:hover {
    text-decoration: underline;
  }
  
  /* Custom Scrollbar */
  .doc-content-area::-webkit-scrollbar {
    width: 8px;
  }
  
  .doc-content-area::-webkit-scrollbar-track {
    background: #222;
  }
  
  .doc-content-area::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 4px;
  }
  
  .doc-sidebar::-webkit-scrollbar {
    width: 5px;
  }
  
  .doc-sidebar::-webkit-scrollbar-track {
    background: #333;
  }
  
  .doc-sidebar::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 3px;
  }
  
  /* Close Button */
  .doc-close-btn {
    position: absolute;
    top: 10px;
    right: 25px;
    background: transparent;
    border: none;
    font-size: 28px;
    color: #fff;
    cursor: pointer;
    z-index: 1;
    margin-right: 10px;
  }
  
  .doc-close-btn:hover,
  .doc-close-btn:focus {
    color: #ffa500;
  }
  
  /* Mobile Responsive */
  @media screen and (max-width: 600px) {
    .doc-modal {
      flex-direction: column;
      width: 95%;
      height: 95%;
    }
    
    .doc-sidebar {
      width: 100%;
      order: 1;
      padding: 10px;
      max-height: 35%;
    }
    
    .doc-content-area {
      order: 2;
      padding: 10px;
      margin-right: 0;
      max-height: 65%;
    }
    
    .doc-close-btn {
      top: 5px;
      right: 15px;
      font-size: 24px;
    }
    
    .doc-sidebar::after {
      display: none;
    }
  }