/* Dynamic Slider Section */

/* Dynamic Slider Section */
.dynamic-slider-section {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    padding: 0 40px;
    position: relative;
  }

.dynamic-slider-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('../public/assets/icons/logo_bg.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20%;
    background-attachment: fixed;
    opacity: 0.1;
    z-index: -1;
}
  
  .dynamic-slider-section.reverse {
    flex-direction: row-reverse;
  }
  
  .slider-container {
    width: 300px;
    height: 615px;
    margin: 0 20px 40px 20px;
  }
  
  /* Smartphone Frame positioned relative to section container */
  .smartphone-frame {
    position: relative;
    width: 300px;
    height: 615px;
    padding: 5px;
    background: linear-gradient(45deg, #1a1a1a, #242424 20%, #2c2c2c 50%, #242424 80%, #1a1a1a);
    border-radius: 36px;
    box-shadow: 0 0 8px rgba(0,0,0,0.6), inset 0 0 15px rgba(0,0,0,0.3);
    overflow: hidden;
  }
  
  .smartphone-frame img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center -19px;
    border-radius: 31px;
  }
  
  /* Slider styles */
  .slider {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .slider .nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0,0,0,0.5);
    padding: 5px 10px;
    border: none;
    border-radius: 0;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
    color: #fff;
  }
  
  .slider .nav.left {
    left: 10px;
  }
  
  .slider .nav.right {
    right: 10px;
  }
  
  .slider .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255,255,255,0.7);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Description with fade-in transition */
  .description {
    flex: 1;
    padding: 20px;
    font-size: 18px;
    line-height: 1.4;
    min-width: 300px;
    position: relative;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
  
  @keyframes fadeIn {
    to { opacity: 1; }
  }
  
  .description h3 {
    color: #ffa500;
    margin-bottom: 10px;
    font-size: 20px;
  }
  
  .description p {
    margin: 0;
  }
  
  .description ul {
    margin: 0;
    padding-left: 20px;
  }
  
  .description li {
    margin-bottom: 8px;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .dynamic-slider-section {
      flex-direction: column;
      align-items: center;
      padding: 0 10px;
      gap: 20px;
    }
    
    .slider-container,
    .description {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }
    
    .description {
      text-align: center;
    }
  }