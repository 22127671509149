/* Base styles */
body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  background-color: #040404;
  color: #fff;
  scroll-behavior: smooth;
}

.fa, .fa-brands {
  transition: transform 0.1s, color 0.1s;
}

.fa:hover, .fa-brands:hover {
  color: #ffa500;
}

.fa:active, .fa-brands:active {
  transform: translate(1px, 1px);
}

/* Glow Title Effect with fade-out */
.glow-title {
  position: relative;
  display: inline-block;
  color: #ffa500;
  animation: textGlow 2.5s ease-out;
}

@keyframes textGlow {
  0% { text-shadow: none; }
  50% { text-shadow: 0 0 20px rgba(255,165,0,0.8), 0 0 30px rgba(255,165,0,0.8); }
  100% { text-shadow: none; }
}

.glow-title::after {
  display: none;
}

.glow-logo {
  filter: brightness(1) drop-shadow(0 0 0px #ffa500);
  animation: logoGlow 2.5s ease-out;
}

@keyframes logoGlow {
  0% { filter: brightness(0.2) drop-shadow(0 0 0px #ffa500); }
  50% { filter: brightness(0.5) drop-shadow(0 0 10px #ffa500); }
  100% { filter: brightness(1) drop-shadow(0 0 0px #ffa500); }
}

.clickable-logo {
  cursor: pointer;
  transition: transform 0.1s, filter 0.1s;
}

.clickable-logo:hover {
  filter: brightness(0.8);
}

.clickable-logo:active {
  transform: translate(1px, 1px);
}

.gitbook-icon {
  transition: transform 0.1s, color 0.1s, filter 0.1s;
}

.gitbook-icon:hover {
  color: #ffa500;
  filter: brightness(1.2);
}

.gitbook-icon:active {
  transform: translate(1px, 1px);
}

/* Section Container */
.section {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto 40px auto;
  background: rgba(31,31,31,0.5);
  border-radius: 20px;
}

/* Footer */
footer {
  text-align: center;
  padding: 20px;
  background: #1f1f1f;
  color: #aaa;
  font-size: 14px;
}

footer a {
  color: #ffa500;
  text-decoration: none;
  margin-left: 10px;
}

/* Spinner animation */
@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Responsive Styles */
@media screen and (max-width: 600px) {
  .section {
    padding: 20px 10px;
    margin-bottom: 20px;
  }
}