/* Section Title with Swish Effect */
.section-title {
    position: relative;
    margin-bottom: 40px;
    overflow: hidden;
  }
  
  .section-title h2 {
    font-size: 36px;
    position: relative;
    z-index: 2;
    opacity: 0;
    transform: translateX(-50px);
    transition: transform 1s, opacity 1s;
    margin: 0;
    padding-left: 20%; /* Off-center: 2/3 from left */
    display: block;
  }
  
  .section-title.reverse h2 {
    padding-left: 0;
    padding-right: 20%;
    text-align: right;
    transform: translateX(50px);
  }
  
  .section-title.visible h2 {
    opacity: 1;
    transform: translateX(0);
  }
  
  .section-title::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 6px;
    width: 100%;
    background: linear-gradient(to right, rgba(0,0,0,0.8) 30%, transparent 100%);
    z-index: 1;
    transform: translateX(-100%);
    transition: transform 1s;
  }
  
  .section-title.reverse::before {
    left: auto;
    right: 0;
    background: linear-gradient(to left, rgba(0,0,0,0.8) 30%, transparent 100%);
    transform: translateX(100%);
  }
  
  .section-title.visible::before {
    transform: translateX(0);
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .section-title h2 {
      font-size: 28px;
    }
    
    .section-title {
      margin-bottom: 30px;
    }
    
    .section-title::before {
      height: 4px;
    }
  }