/* Home Section */
.home-section {
    text-align: center;
    padding: 40px 20px;
  }
  
  .home-section h1 {
    font-family: 'Orbitron', sans-serif;
    font-size: 120px;
    color: #ffa500;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  
  .home-section p {
    font-size: 20px;
    color: #aaa;
  }
  
  /* Social Icons */
  .social-links {
    text-align: center;
  }
  
  .social-links a {
    color: #fff;
    font-size: 24px;
    margin: 0 16px;
    text-decoration: none;
  }
  
  /* Statistics */
  .stats-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0px 0;
    padding: 20px;
  }
  
  .stat-item {
    text-align: center;
    margin: 10px;
    padding: 15px;
    background: rgba(255,255,255,0.05);
    border-radius: 10px;
    min-width: 200px;
    transition: transform 0.3s ease;
  }
  
  .stat-item:hover {
    transform: translateY(-5px);
  }
  
  .stat-item h3 {
    color: #ffa500;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .stat-item p {
    font-size: 16px;
  }
  
  .stat-item:hover h3 {
    text-shadow: 0 0 8px #ffa500;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .home-section h1 {
      font-size: 60px;
    }
    
    .stats-container {
      flex-direction: column;
      align-items: center;
    }
    
    .social-links a {
      margin: 0 10px;
    }
  }