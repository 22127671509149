/* Partners Carousel: continuous marquee with fading sides */
.partners-carousel {
    overflow: hidden;
    position: relative;
    mask-image: linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%);
    padding: 0 20px;
  }
  
  .marquee {
    display: flex;
    animation: marquee 24s linear infinite;
  }
  
  @keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }
  
  .marquee img {
    width: 120px;
    height: auto;
    margin-right: 10px;
  }
  
  .marquee.reverse {
    animation-direction: reverse;
  }
  
  .marquee2 {
    display: flex;
    animation: marquee 24s linear infinite;
  }
  
  .marquee2 img {
    width: 45px;
    height: auto;
    margin-right: 60px;
  }
  
  .marquee2.reverse {
    animation-direction: reverse;
  }
  
  .partners-carousel + .partners-carousel {
    margin-top: 40px;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .marquee, .marquee.reverse, .marquee2, .marquee2.reverse {
      animation-duration: 6s !important;
    }
    
    .marquee img {
      width: 80px;
    }
    
    .marquee2 img {
      width: 35px;
      margin-right: 30px;
    }
  }