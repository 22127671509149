/* Modal Styles */
.tos-modal {
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .tos-modal-content {
    background-color: #040404;
    padding: 20px;
    border: 1px solid #333;
    width: 80%;
    max-width: 800px;
    border-radius: 10px;
    color: #fff;
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .tos-modal-content h1,
  .tos-modal-content h2 {
    color: #ffa500;
  }
  
  .tos-close-btn {
    color: #fff;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
  }
  
  .tos-close-btn:hover,
  .tos-close-btn:focus {
    color: #ffa500;
    text-decoration: none;
  }
  
  /* Scrollbar Styling */
  .tos-modal-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .tos-modal-content::-webkit-scrollbar-track {
    background: #1a1a1a;
  }
  
  .tos-modal-content::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 4px;
  }
  
  .tos-modal-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .tos-modal-content {
      width: 90%;
      padding: 15px;
      max-height: 90vh;
    }
    
    .tos-close-btn {
      top: 5px;
      right: 15px;
      font-size: 24px;
    }
  }