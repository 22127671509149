/* Team Section */
.team-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .team-member {
    text-align: center;
    background: rgba(255,255,255,0.05);
    padding: 20px;
    border-radius: 10px;
    flex: 1;
    min-width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .team-member:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  
  .team-member img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
  }
  
  .team-member:hover img {
    transform: scale(1.05);
  }
  
  .team-member h3 {
    color: #ffa500;
    margin: 10px 0;
  }
  
  .team-member a:hover i.fa-telegram {
    color: #ffa500 !important;
    filter: brightness(1.2);
    transition: color 0.1s, filter 0.1s;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .team-container {
      flex-direction: column;
      align-items: center;
    }
    
    .team-member {
      width: 80%;
    }
  }