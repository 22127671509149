/* Navbar */
.navbar {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #1f1f1f;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .logo img {
    height: 40px;
  }
  
  .nav-buttons {
    margin: 0 auto;
  }
  
  .navbar button {
    background: none;
    border: none;
    color: #fff;
    margin: 0 15px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s;
  }
  
  .navbar button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffa500;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .navbar button:hover,
  .navbar button.active {
    color: #ffa500;
  }
  
  .navbar button.active::after {
    transform: scaleX(1);
  }
  
  /* Mobile Navbar (Hamburger) */
  .hamburger-menu {
    display: none;
  }
  
  .mobile-nav {
    position: absolute;
    top: 60px;
    left: 0;
    background: #1f1f1f;
    width: 250px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  
  .mobile-nav button {
    background: #1f1f1f;
    border: none;
    color: #fff;
    padding: 15px 20px;
    text-align: left;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    border-bottom: 1px solid #333;
    margin: 0;
  }
  
  .mobile-nav button:hover {
    background: #333;
    color: #ffa500;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .navbar {
      flex-direction: column;
      text-align: center;
      position: relative;
    }
    
    .nav-buttons {
      display: none;
    }
    
    .hamburger-menu {
      display: block;
      position: fixed;
      top: 17px;
      left: 17px;
      z-index: 1002;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #ffa500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .hamburger-menu button {
      background: none;
      border: none;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }