/* Coming Soon Analyzer */
.coming-soon-analyzer {
    background: rgba(37, 37, 37, 0.65);
    padding: 40px 20px;
    border-radius: 10px;
    text-align: center;
    margin: 20px auto;
    max-width: 600px;
    border: 1px solid rgba(255, 165, 0, 0.3);
  }
  
  .coming-soon-analyzer h3 {
    color: #ffa500;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .coming-soon-analyzer p {
    font-size: 18px;
    color: #ccc;
    margin-bottom: 20px;
  }
  
  .try-bot-button {
    display: inline-block;
    background-color: #1f1f1f;
    color: #ffa500;
    padding: 12px 24px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s, transform 0.2s;
    border: 1px solid #ffa500;
    margin-top: 10px;
  }
  
  .try-bot-button:hover {
    background-color: rgba(255, 165, 0, 0.2);
    transform: translateY(-2px);
  }
  
  .try-bot-button:active {
    transform: translateY(1px);
  }
  
  .try-bot-button i {
    margin-left: 8px;
  }
  
  /* Spinner for when we re-enable the analyzer */
  .spinner {
    margin: 30px auto;
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 165, 0, 0.3);
    border-top-color: #ffa500;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }